export enum EventType {
  AccountConnected = 'AccountConnected',
  CloseModal = 'CloseModal',
  CloseOAuth2 = 'CloseOAuth2',
}

interface ConnectEvent {
  type: EventType;
  account?: any;
}

interface InlineStyles {
  containerId: string;
  width?: string;
  height?: string;
}

interface OptionsStyles {
  back?: boolean;
  close?: boolean;
  bgColor?: string;
}

interface HubStyles {
  inline?: InlineStyles;
  options?: OptionsStyles;
}

interface StartOptions {
  sessionToken: string;
  apiUrl?: string;
  styles?: HubStyles;
  onSuccess?: (account) => void;
  onCancel?: () => void;
  onClose?: () => void;
}

export class Connect {
  private static iframe?: HTMLIFrameElement;
  private static isInstalled: boolean = false;
  private static onSuccess?: (account) => void;
  private static onCancel?: () => void;
  private static onClose?: () => void;

  public static start({
    sessionToken,
    apiUrl,
    styles,
    onSuccess,
    onCancel,
    onClose,
  }: StartOptions) {
    const url = apiUrl || 'https://app.stackone.com';
    this.installListener();
    if(this.iframe) {
      this.close();
    }
    
    this.onSuccess = onSuccess;
    this.onCancel = onCancel;
    this.onClose = onClose;
    this.iframe = document.createElement('iframe');
    this.iframe.src = this.createConnectUrl(sessionToken, url, styles);

    if (!styles) {
      this.iframe.style.position = 'fixed';
      this.iframe.style.zIndex = '9999';
      this.iframe.style.left = '0';
      this.iframe.style.right = '0';
      this.iframe.style.top = '0';
      this.iframe.style.bottom = '0';
      this.iframe.style.width = '100%';
      this.iframe.style.height = '100vh';
      this.iframe.style.backgroundColor = 'transparent';

      document.body.append(this.iframe);
    }
    else {
      this.iframe.style.width = styles.inline?.width || '100%';
      this.iframe.style.height = styles.inline?.height || '100vh';
      this.iframe.style.backgroundColor = 'transparent';
      this.iframe.style.border = 'none';

      if(styles.inline) {
        if(styles.inline.containerId) {
          const container = document.getElementById(styles.inline.containerId);
          if (container) {
            container.appendChild(this.iframe);
          }
        } else {
          document.body.append(this.iframe);
        }
      }
    }
  }

  public static close() {
    if (this.iframe) {
      document.body.removeChild(this.iframe);
      this.iframe = undefined;
    }
    this.onClose?.();
  }

  public static cancel() {
    if (this.iframe) {
      document.body.removeChild(this.iframe);
      this.iframe = undefined;
    }
    this.onCancel?.();
  }

  private static installListener() {
    if (this.isInstalled) return;

    window.addEventListener('message', this.processMessage.bind(this), false);
    this.isInstalled = true;
  }

  private static processMessage(e: MessageEvent<ConnectEvent>) {
    if (!this.iframe || e.source !== this.iframe.contentWindow) return;

    if (e.data.type === EventType.CloseModal) {
      this.cancel();
    } else if (e.data.type === EventType.AccountConnected) {
      setTimeout(this.close.bind(this), 1500);
      this.onSuccess?.(e.data.account);
    }
  }

  private static createConnectUrl(sessionToken: string, apiUrl: string, styles?: HubStyles): string {
    const url = new URL(`${apiUrl}/embedded/integrations`);
  
    url.searchParams.append('token', sessionToken);
  
    if (styles) {
      this.appendStyleParams(url, styles);
    }
    
    return url.toString();
  }

  private static appendStyleParams(url: URL, styles: HubStyles): void {
    if (styles.inline) {
      url.searchParams.append('display', 'inline');

      if (styles.inline.width) {
        url.searchParams.append('width', styles.inline.width);
      }

      if (styles.inline.height) {
        url.searchParams.append('height', styles.inline.height);
      }
    }
    
    if (styles.options?.back === false) {
      url.searchParams.append('back', 'false');
    }

    if (styles.options?.close === false) {
      url.searchParams.append('close', 'false');
    }

    if (styles.options?.bgColor) {
      url.searchParams.append('bgColor', styles.options.bgColor);
    }
  }
}
